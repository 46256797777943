export const PermissiveNameMatch = {
  MESSAGE: 'Ingrese solo letras, números, paréntesis, comas, comillas, guiones o puntos',
  REGEX_PATTERN: /^[-a-zA-ZáéíóúüñÁÉÍÓÚÜÑ\s\.\,\/\'0-9,()"]*$/
};
export const PermissiveNameMatchPlus = {
  MESSAGE: 'Ingrese solo letras, números, paréntesis, comas, comillas, guiones, puntos o signo más',
  REGEX_PATTERN: /^[-a-zA-ZáéíóúüñÁÉÍÓÚÜÑ\s\.\,\/\'0-9,()+"+]*$/
};
export const RestrictedNameMatch = {
  MESSAGE: 'Ingrese solo letras, guiones o puntos',
  REGEX_PATTERN: /^[A-Za-zÁáÉéÍíÓóÚúÜüÑñÇç. -]*$/
};
export const UserNameMatch = {
  MESSAGE: 'Ingrese solo letras y números',
  REGEX_PATTERN: /^[A-Za-z0-9]+$/
};
export const PasswordMatch = {
  MESSAGE: 'Debe tener mínimo 8 caracteres incluyendo una letra mayúscula, una minúscula y un número.',
  REGEX_PATTERN: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?:[A-Za-z0-9ñÑáéíóúüÁÉÍÓÚÜ¡¿.,:;_\-\+\\/\\\[\]\{\}\?\!@#$%^&*()_+\'=,~]+){8,}$/
};
export const CostCenterMatch = {
  MESSAGE: 'Ingrese solo letras, números, guíones o puntos',
  REGEX_PATTERN: /^[a-zA-Z0-9.-]+.[a-zA-Z0-9.-]+$/
};
export const DescriptionMatch = {
  MESSAGE: 'Ingrese caracteres válidos',
  REGEX_PATTERN: /^[a-zA-Z0-9áéíóúüñÁÉÍÓÚÜÑ\s\.\,\?\!\;\:\-\+\*\=\/\(\)\[\]\{\}<>%&#@_\$\^\€\#\£\¥\©\®™\ªº¿¡¨´]/
};
export const QuantityMatch = {
  MESSAGE: 'La cantidad puede tener hasta 6 decimales',
  REGEX_PATTERN: /^\d+(?:\.\d{1,6})?$/
};
export const CodeMatch = {
  MESSAGE: 'no puede comenzar ni terminar con un guion.',
  REGEX_PATTERN: /^(?=[a-zA-Z0-9-]{2,7}$)[a-zA-Z0-9]+(-[a-zA-Z0-9]+)?$/
};
export const DecimalMatch = (decimalQuantityMatch: number) => {
  return {
    MESSAGE: `La cantidad puede tener hasta ${decimalQuantityMatch} decimales`,
    REGEX_PATTERN: new RegExp(`^\\d+(?:\\.\\d{1,${decimalQuantityMatch}})?$`)
  };
};
export const ProductionReviewMatch = {
  MESSAGE: 'La cantidad puede tener hasta 6 decimales',
  REGEX_PATTERN: /^(0|[1-9]\d*)(\.\d{1,6})?$/
};
export const PerformanceMatch = {
  MESSAGE: 'El rendimiento debe tener hasta 10 decimales como maximo',
  REGEX_PATTERN: /^\d+(\.\d{1,10})?$/
};
export const PriceMatch = {
  MESSAGE: 'El precio debe ser mayor que 0 y tener hasta 4 decimales',
  REGEX_PATTERN: /^[+]?[0-9]+(\.[0-9]{1,4})?$/
};
export const PhoneMatch = {
  MESSAGE: 'El télefono solo puede tener el formato: 0000-0000',
  REGEX_PATTERN: /^[0-9]{4}-[0-9]{4}$/
};
export const IntegerMatch = {
  MESSAGE: 'Solo puede ingresar números enteros',
  REGEX_PATTERN: /^[0-9]+$/
};
export const CapitalLettersMatch = {
  MESSAGE: 'Solo puede ingresar letras mayúsculas',
  REGEX_PATTERN: /^[A-ZÑ]+$/
};
export const CommonValidationMessages = {
  isRequiredToEnter: function (fieldName: string) {
    return `Es requerido ingresar ${fieldName}`;
  },
  isRequiredToSelect: function (fieldName: string) {
    return `Es requerido seleccionar ${fieldName}`;
  },
  maxLength: function (quantity: number, fieldName: string) {
    return `${fieldName?.charAt(0).toUpperCase() + fieldName?.slice(1)} puede tener hasta ${quantity} caracteres`;
  },
  minLength: function (quantity: number, fieldName: string) {
    return `${fieldName?.charAt(0).toUpperCase() + fieldName?.slice(1)} debe tener mínimo ${quantity} caracteres`;
  },
  mustBeGreaterThanZero: function (fieldName: string) {
    return `${fieldName?.charAt(0).toUpperCase() + fieldName?.slice(1)} debe ser mayor a 0`;
  }
};