export enum UsersMutationKeys {
  ASSIGN_PROJECTS = 'user-assign-projects',
  BLOCK = 'user-block',
  CHANGE_PASSWORD = 'user-change-password',
  CHANGE_PROFILE_PICTURE = 'user-profile-picture',
  CREATE = 'user-create',
  REMOVE_PROJECTS = 'user-remove-projects',
  RESET_PASSWORD = 'user-reset-password',
  ROLE_ASSIGN = 'user-role-assign',
  ROLE_REVOKE = 'user-rolerevoke',
}

export enum ProjectActivitiesMutationKeys {
  CREATE = 'project-activities-create',
  DELETE = 'project-activities-delete',
  UPDATE = 'project-activities-update',
}

export enum ProjectActivityChecksMutationKeys {
  CREATE = 'project-activity-checks-create',
  DELETE = 'project-activity-checks-delete',
  DELETEIMAGE = 'project-activity-checks-delete-image',
  IMPORT = 'project-activity-checks-import',
  REPORT = 'project-activity-checks-report',
  UPDATE = 'project-activity-checks-update',
}

export enum ProjectAcitivityTypesMutationKeys {
  CREATE = 'project-activities-type-create',
  DELETE = 'project-activities-type-delete',
  UPDATE = 'project-activities-type-update',
}

export enum ProjectScheduleMutationKeys {
  COPY_SCHEDULE = 'project-schedule-copy',
  CREATE = 'project-schedule-create',
  DELETE = 'project-schedule-delete',
  IMPORT = 'project-schedule-import',
  UPDATE = 'project-schedule-update',
  UPDATECRITICALPATH = 'project-schedule-update-critical-path',
  UPDATEWORKDAY = 'project-schedule-update-workday',
}

export enum ActivitiesScheduleMutationKeys {
  ADDHOLIDAY = 'project-schedule-add-holiday',
  ADDPREDECESSOR = 'project-schedule-add-processor',
  CHANGE_POSITION = 'activity-schedule-change-position',
  CREATE = 'project-schedule-create',
  DELETE = 'project-schedule-delete',
  UPDATE = 'project-schedule-update',
}

export enum CityMutationKeys {
  CREATE = 'city-create',
  DELETE = 'city-delete',
  UPDATE = 'city-update',
}

export enum ActivitiesWithAddCostReportMutationKeys {
  REPORT = 'activities-with-add-cost-report',
}

export enum CountryMutationKeys {
  CREATE = 'country-create',
  DELETE = 'country-delete',
  UPDATE = 'country-update',
}

export enum DepartmentsMutationKeys {
  CREATE = 'departments-create',
  DELETE = 'departments-delete',
  UPDATE = 'departments-update',
}

export enum GenderMutationKeys {
  CREATE = 'genders-create',
  DELETE = 'genders-delete',
  UPDATE = 'genders-update',
}

export enum RoleMutationKeys {
  CREATE = 'role-create',
  DELETE = 'role-delete',
  UPDATE = 'role-update',
}

export enum MeasurementUnitsMutationKeys {
  CREATE = 'measurement-units-create',
}

export enum LaborsMutationKeys {
  CREATE = 'labors-create',
  DELETE = 'labors-delete',
  IMPORT = 'labors-import',
  REPORT = 'labors-report',
  UPDATE = 'labors-update',
}

export enum LaborTypesMutationKeys {
  CREATE = 'labor-types-create',
  DELETE = 'labor-types-delete',
  UPDATE = 'labor-types-update',
}

export enum MachineryMutationKeys {
  CREATE = 'machinery-create',
  DELETE = 'machinery-delete',
  IMPORT = 'machinery-import',
  PRICE_REPORT = 'machinery-price-report',
  REPORT = 'machinery-report',
  UPDATE = 'machinery-update',
}

export enum MachineryFamilyMutationKeys {
  CREATE = 'machinery-family-create',
  DELETE = 'machinery-family-delete',
  IMPORT = 'machinery-family-import',
}

export enum MachineryAdjustmentMutationKeys {
  CREATE = 'machinery-adjustment-create',
  DELETE = 'machinery-adjustment-delete',
  UPDATE = 'machinery-adjustment-update',
}

export enum MachineryTypeMutationKeys {
  CREATE = 'machinery-type-create',
  DELETE = 'machinery-type-delete',
  IMPORT = 'machinery-type-import',
}

export enum MaterialsMutationKeys {
  CREATE = 'materials-create',
  DELETE = 'materials-delete',
  IMPORT = 'materials-import',
  REPORT = 'materials-report',
  UPDATE = 'materials-update',
}

export enum MaterialTypesMutationKeys {
  CREATE = 'material-types-create',
  DELETE = 'material-types-delete',
  UPDATE = 'material-types-update',
}

export enum MeasurementMutationKeys {
  CREATE = 'measurement-create',
  DELETE = 'measurement-delete',
  UPDATE = 'measurement-update',
}

export enum MeasurementUnitConversionMutationKeys {
  CONVERT = 'measurement-unit-conversion-convert',
  CREATE = 'measurement-unit-conversion-create',
  DELETE = 'measurement-unit-conversion-delete',
  UPDATE = 'measurement-unit-conversion-update',
}

export enum MeasurementUnitTypesMutationKeys {
  CREATE = 'measurement-unit-types-create',
  DELETE = 'measurement-unit-types-delete',
  UPDATE = 'measurement-unit-types-update',
}
export enum ProjectMutationKeys {
  CREATE = 'project-create',
  DELETE = 'project-delete',
  UPDATE = 'project-update',
  UPDATE_USERS = 'project-update-users',
}

export enum ProjectAreasMutationKeys {
  CREATE = 'project-areas-create',
  DELETE = 'project-areas-delete',
  UPDATE = 'project-areas-update',
}

export enum ProjectCategoriesMutationKeys {
  CREATE = 'project-categories-create',
  DELETE = 'project-categories-delete',
  UPDATE = 'project-categories-update',
}

export enum StatusMutationKeys {
  CREATE = 'status-create',
  DELETE = 'status-delete',
  UPDATE = 'status-update',
}

export enum StatuTypesMutationKeys {
  CREATE = 'status-types-create',
  DELETE = 'status-types-delete',
  UPDATE = 'status-types-update',
}

export enum ProductionReviewMutationKeys {
  CREATE = 'production-review-create',
  DELETE = 'production-review-delete',
}

export enum MachineryPriceHistoryMutationKeys {
  CREATE = 'machinery-price-history-create',
  DELETE = 'machinery-price-history-delete',
  IMPORT = 'machinery-price-history-import',
  UPDATE = 'machinery-price-history-update',
}

export enum MaterialPriceHistoryMutationKeys {
  CREATE = 'material-price-history-create',
  DELETE = 'material-price-history-delete',
  IMPORT = 'material-price-history-import',
  UPDATE = 'material-price-history-update',
}
export enum laborPriceHistoryMutationKeys {
  CREATE = 'labor-price-history-create',
  DELETE = 'labor-price-history-delete',
  IMPORT = 'labor-price-history-import',
  UPDATE = 'labor-price-history-update',
}

export enum LaborAdjustmentMutationKeys {
  CREATE = 'labor-adjustment-create',
  DELETE = 'labor-adjustment-delete',
}
export enum MaterialAdjustmentMutationKeys {
  CREATE = 'material-adjustment-create',
  DELETE = 'material-adjustment-delete',
}

export enum IncomeTrackMutationKeys {
  CREATE = 'income-track-create',
  DELETE = 'income-track-delete',
  UPDATE = 'income-track-update',
  UPDATEDOCUMENT = 'income-track-update-document',
}

export enum IncomeActivityTrackMutationKeys {
  CREATE = 'income-activity-track-create',
  DELETE = 'income-activity-track-delete',
  UPDATE = 'income-activity-track-update',
}

export enum ConstraintsMutationKeys {
  CREATE = 'constraints-create',
  DELETE = 'constraints-delete',
  UPDATE = 'constraints-update',
}
export enum ContractorsMutationKeys {
  CREATE = 'contractor-create',
  DELETE = 'contractor-delete',
  UPDATE = 'contractor-update',
}

export enum ExchangeRatesMutationKeys {
  CREATE = 'exchange-rates-create',
  DELETE = 'exchange-rates-delete',
  UPDATE = 'exchange-rates-update',
}
export enum ActivityContractorsMutationKeys {
  CREATE = 'activity-contractor-create',
  DELETE = 'activity-contractor-delete',
  UPDATE = 'activity-contractor-update',
}

export enum ProjectContractorsMutationKeys {
  CREATE = 'project-contractor-create',
  DELETE = 'project-contractor-delete',
  UPDATE = 'project-contractor-update',
}
